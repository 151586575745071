import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TypeFormat } from "@wttb/product-configurator";

import { ModalView, useUI } from "../../../common/contexts/ui-context";
import { createSettings, updateSettingsById } from "../api/settings";
import { Settings } from "../types";

export const defaultSettings = {
  id: "new",
  key: "",
  format: TypeFormat.String,
  value: "",
};

const updateOrCreateSettings = async (id: string, data: Omit<Settings, "id">) => {
  if (id === "new")
    return createSettings({
      ...data,
    });
  return updateSettingsById(id, data);
};

export function useUpdateSettings(id: string) {
  const queryClient = useQueryClient();
  const { openModal } = useUI();

  return useMutation<Settings, Error, Omit<Settings, "id">>({
    mutationFn: (data) => updateOrCreateSettings(id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["SETTINGS"] });
      if (id === "new") {
        openModal(ModalView.updateSettings, { data });
      }
      return data;
    },
  });
}
