"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProduct = exports.ProductContextProvider = exports.ProductContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var utils_1 = require("./utils");
var initialState = {
    config: null,
    deliveryOption: null,
    attributes: [],
};
exports.ProductContext = (0, react_1.createContext)(initialState);
exports.ProductContext.displayName = "ProductContext";
var ProductContextProvider = function (_a) {
    var children = _a.children, _b = _a.attributes, attributes = _b === void 0 ? [] : _b, _c = _a.warnings, initialWarnings = _c === void 0 ? [] : _c;
    var _d = (0, react_1.useState)(__assign(__assign({}, initialState), { config: __assign({}, (0, utils_1.generateInitialConfig)(attributes)), attributes: attributes })), state = _d[0], setState = _d[1];
    var _e = (0, react_1.useState)(initialWarnings), warnings = _e[0], setWarnings = _e[1];
    var _f = (0, react_1.useState)({}), incompatibilities = _f[0], setIncompatibilities = _f[1];
    var isConfigValid = (0, react_1.useMemo)(function () { return (0, utils_1.validateConfig)(state.config); }, [state.config]);
    var attributesHash = (0, react_1.useMemo)(function () {
        return (0, utils_1.getAttributesHash)(state.attributes);
    }, [state.attributes]);
    var incompatibilitiesHash = (0, react_1.useMemo)(function () {
        return (0, utils_1.getIncompatibilitiesHash)(state.attributes);
    }, [state.attributes]);
    var warningHash = (0, react_1.useMemo)(function () {
        return (0, utils_1.getAttributeWarningsHash)(warnings);
    }, [warnings]);
    var getUpdatedIncompatibilities = (0, react_1.useCallback)(function (valueId, incompatibleAttributeKey, incompatibilities) {
        var incompatibleValues = incompatibilitiesHash[valueId];
        if (!(incompatibleValues === null || incompatibleValues === void 0 ? void 0 : incompatibleValues.length))
            return incompatibilities;
        return incompatibleValues.reduce(function (acc, value) {
            var _a;
            var key = value.incompatibleAttribute.key;
            if (value.attribute.key === incompatibleAttributeKey) {
                incompatibilities[key] = ((_a = incompatibilities[key]) === null || _a === void 0 ? void 0 : _a.length)
                    ? __spreadArray(__spreadArray([], incompatibilities[key], true), [value], false) : [value];
            }
            return acc;
        }, incompatibilities);
    }, [incompatibilitiesHash]);
    var getUpdatedWarnings = (0, react_1.useCallback)(function (key, valueId, warnings) {
        var cleanWarnings = (0, utils_1.removeAttributeWarnings)(key, warnings);
        var ids = Array.isArray(valueId) ? valueId : [valueId];
        return ids.reduce(function (acc, id) {
            var _a;
            var warning = (_a = warningHash[key]) === null || _a === void 0 ? void 0 : _a[id];
            if (warning)
                acc.push(warning);
            return acc;
        }, cleanWarnings);
    }, [warningHash]);
    var setConfigValue = (0, react_1.useCallback)(function (key, valueId) {
        setState(function (state) {
            var _a;
            setWarnings(function (prev) { return getUpdatedWarnings(key, valueId, prev); });
            setIncompatibilities(function (prevIncompatibilities) {
                var newIncompatibilities = (0, utils_1.removeConfigValueIncompatibleValues)(key, structuredClone(prevIncompatibilities));
                if (Array.isArray(valueId)) {
                    valueId.forEach(function (id) {
                        newIncompatibilities = getUpdatedIncompatibilities(id, key, newIncompatibilities);
                    });
                }
                else {
                    newIncompatibilities = getUpdatedIncompatibilities(valueId, key, prevIncompatibilities);
                }
                return newIncompatibilities;
            });
            var value = (0, utils_1.getAttributeValueByIdOrIds)(key, valueId, attributesHash);
            var newConfigValues;
            if (Array.isArray(valueId)) {
                newConfigValues = valueId.reduce(function (acc, cur) {
                    return __assign(__assign({}, acc), (0, utils_1.calcProductConfigValueChange)({
                        incompatibilitiesHash: incompatibilitiesHash,
                        productConfig: state.config,
                        attributesHash: attributesHash,
                        valueId: cur,
                    }));
                }, {});
            }
            else {
                newConfigValues = (0, utils_1.calcProductConfigValueChange)({
                    incompatibilitiesHash: incompatibilitiesHash,
                    productConfig: state.config,
                    attributesHash: attributesHash,
                    valueId: valueId,
                });
            }
            return __assign(__assign({}, state), { config: __assign(__assign(__assign({}, state.config), newConfigValues), (_a = {}, _a[key] = value, _a)) });
        });
    }, [attributesHash]);
    var setCustomConfigValue = (0, react_1.useCallback)(function (key, value) {
        setState(function (state) {
            var _a;
            setIncompatibilities(function (prevIncompatibilities) {
                return (0, utils_1.removeConfigValueIncompatibleValues)(key, structuredClone(prevIncompatibilities));
            });
            setWarnings(function (prevWarnings) {
                return (0, utils_1.removeAttributeWarnings)(key, prevWarnings);
            });
            return __assign(__assign({}, state), { config: __assign(__assign({}, state.config), (_a = {}, _a[key] = value, _a)) });
        });
    }, []);
    var getIncompatibilitiesAndWarnings = (0, react_1.useCallback)(function (curAttributes, config) {
        var _a = curAttributes.reduce(function (acc, attr) {
            var value = attr.values.find(function (attrValue) { return attrValue[attr.returnKey] === config[attr.key]; });
            if (value) {
                acc.incompatibilities = getUpdatedIncompatibilities(value.id, attr.key, acc.incompatibilities);
                acc.warnings = getUpdatedWarnings(attr.key, value.id, acc.warnings);
            }
            return acc;
        }, { incompatibilities: {}, warnings: [] }), incompatibilities = _a.incompatibilities, warnings = _a.warnings;
        return { incompatibilities: incompatibilities, warnings: warnings };
    }, [getUpdatedIncompatibilities]);
    (0, react_1.useEffect)(function () {
        var _a = getIncompatibilitiesAndWarnings(state.attributes, state.config), incompatibilities = _a.incompatibilities, warnings = _a.warnings;
        setIncompatibilities(incompatibilities);
        setWarnings(warnings);
    }, [state.config, state.attributes]);
    var setProductConfig = (0, react_1.useCallback)(function (updatedConfig) {
        setState(function (state) {
            var _a = getIncompatibilitiesAndWarnings(state.attributes, updatedConfig), incompatibilities = _a.incompatibilities, warnings = _a.warnings;
            setIncompatibilities(incompatibilities);
            setWarnings(warnings);
            return __assign(__assign({}, state), { config: __assign({}, updatedConfig) });
        });
    }, []);
    var value = __assign(__assign({}, state), { attributesHash: attributesHash, incompatibilitiesHash: incompatibilitiesHash, isConfigValid: isConfigValid, incompatibilities: incompatibilities, warnings: warnings, setConfigValue: setConfigValue, setCustomConfigValue: setCustomConfigValue, setProductConfig: setProductConfig });
    return ((0, jsx_runtime_1.jsx)(exports.ProductContext.Provider, { value: value, children: children }));
};
exports.ProductContextProvider = ProductContextProvider;
var useProduct = function () {
    var context = (0, react_1.useContext)(exports.ProductContext);
    if (context === undefined) {
        throw new Error("useProduct must be used within a ProductProvider");
    }
    return context;
};
exports.useProduct = useProduct;
