import { internalApiFetcher } from "../../../clients/httpFetchers";
import { ROUTES } from "../../../clients/routes-config";

export enum PrintProcess {
  Digital = "digital",
  LithoSheet = "lithoSheet",
}

export type PrintRate = {
  id: string;
  process: PrintProcess;
  maxSubstrateHeight: number;
  maxSubstrateWidth: number;
  monoOneSideCost: number;
  monoTwoSidesCost: number;
  colourOneSideCost: number;
  colourTwoSidesCost: number;
  uplift: number;
  createdAt: string;
  updatedAt: string;
};

export const findPrintRates = async ({
  where,
  page,
  pageSize,
}: {
  where: Record<string, unknown>;
  page: number;
  pageSize: number;
}) => {
  return internalApiFetcher(`${ROUTES.printRates}/paginated`, {
    method: "GET",
    params: { where, page, pageSize },
  });
};

export const updatePrintRate = async (id: string, data: Partial<PrintRate>): Promise<PrintRate> => {
  return internalApiFetcher(`${ROUTES.printRates}/${id}`, {
    method: "PUT",
    data,
  });
};

export const createPrintRate = async (
  data: Omit<PrintRate, "id" | "createdAt" | "updatedAt">,
): Promise<PrintRate> => {
  return internalApiFetcher(`${ROUTES.printRates}`, {
    method: "POST",
    data,
  });
};

export const deleteRateById = async (id: string): Promise<void> => {
  return internalApiFetcher(`${ROUTES.printRates}/${id}`, {
    method: "DELETE",
  });
};
