import { useMemo, useState } from "react";
import {
  Card,
  CheckboxInput,
  ContentNotification,
  Label,
  LoadingSpinner,
  PrimaryButton,
  SelectField,
  Spacings,
  TextInput,
} from "@commercetools-frontend/ui-kit";
import { TCustomEvent } from "@commercetools-uikit/select-field";
import { AttributeType, ProductAttribute } from "@wttb/product-configurator";
import { useFormik } from "formik";

import { useUI } from "../../../common/contexts/ui-context";
import { useNotification } from "../../../common/hooks/use-notification";
import { useFindAllAttributeTypes } from "../../attribute-type/hooks/use-find-all-attribute-types";
import { useFindAttributeValuesByType } from "../../attribute-value/hooks/use-find-values-by-type";
import { useAddValues } from "../hooks/use-add-values";

type ModalTypeData = {
  productId: string;
  attributes: ProductAttribute[];
};

type OptionType = {
  value: string;
  label: string;
};

export const AddAttributeForm: React.FC = () => {
  const { modalData } = useUI() as { modalData: ModalTypeData };
  const [isDefaultKeyUsed, setIsDefaultKeyUsed] = useState(true);
  const [selectedType, setSelectedType] = useState<null | AttributeType>(null);
  const addValues = useAddValues(modalData.productId);

  const formik = useFormik({
    initialValues: {
      typeId: "",
      key: "",
      valueIds: [],
    },
    onSubmit: (values) => addValues.mutateAsync(values),
  });
  const types = useFindAllAttributeTypes();
  const values = useFindAttributeValuesByType(formik.values.typeId);

  useNotification(types.error || values.error);

  const typeOptions = useMemo(() => {
    if (!types.data?.items) return [];
    const usedTypeIds = modalData.attributes.map((attr) => attr.typeId);
    const options = types.data.items.reduce(
      (acc, type) => {
        const option: OptionType = { value: type.id, label: type.name };
        if (usedTypeIds.includes(type.id)) {
          acc.used.push(option);
        } else {
          acc.new.push(option);
        }
        return acc;
      },
      { used: [], new: [] } as { used: OptionType[]; new: OptionType[] },
    );
    return [
      {
        label: "Already used types",
        options: options.used,
      },
      {
        label: "Types to select",
        options: options.new,
      },
    ];
  }, [types.data, modalData.attributes]);

  const valueOptions = useMemo(() => {
    if (!formik.values.typeId || !values.data?.items) return [];
    return values.data?.items.map((value) => ({
      value: value.id! as string,
      label: value.display !== value.value ? `${value.display} (${value.value})` : value.display,
    }));
  }, [values.data, formik.values.typeId]);

  const keyOptions = useMemo(() => {
    if (!selectedType || !formik.values.typeId) return [];
    const selectedKeys = modalData.attributes
      .filter((attr) => attr.typeId === formik.values.typeId)
      .map((attr) => attr.key);
    if (!selectedKeys.includes(selectedType.name)) selectedKeys.push(selectedType.name);
    return selectedKeys.map((key) => ({ label: key, value: key }));
  }, [selectedType, formik.values.typeId, modalData.attributes]);

  function handleTypeIdChange(event: TCustomEvent) {
    const type = types.data?.items.find((type) => type.id === event.target.value);
    type && setSelectedType(type);
    formik.setFieldValue("key", type?.name || "");
    formik.handleChange(event);
  }

  function handleCheckBoxClick(value: boolean) {
    if (value && selectedType) {
      formik.setFieldValue("key", selectedType.name);
    }
    setIsDefaultKeyUsed(value);
  }

  return (
    <Card type="flat" insetScale="none">
      <form onSubmit={formik.handleSubmit}>
        <Spacings.Stack scale="xl">
          <SelectField
            id="typeId"
            title="Attribute Type"
            value={formik.values.typeId}
            showOptionGroupDivider={true}
            options={typeOptions}
            onChange={handleTypeIdChange}
            menuPortalZIndex={100000000}
            menuPortalTarget={document.body}
          />

          <SelectField
            id="valueIds"
            title="Type Values"
            value={formik.values.valueIds}
            isDisabled={!formik.values.typeId}
            isMulti={true}
            options={valueOptions}
            onChange={formik.handleChange}
            menuPortalZIndex={100000000}
            menuPortalTarget={document.body}
          />

          {selectedType && formik.values.typeId && (
            <Spacings.Stack>
              {isDefaultKeyUsed ? (
                <SelectField
                  id="key"
                  title="Attribute Key"
                  value={formik.values.key}
                  options={keyOptions}
                  onChange={formik.handleChange}
                  menuPortalZIndex={100000000}
                  menuPortalTarget={document.body}
                />
              ) : (
                <Spacings.Stack>
                  <Label>Attribute Key</Label>
                  <TextInput
                    id="key"
                    name="key"
                    isDisabled={isDefaultKeyUsed}
                    value={formik.values.key}
                    onChange={formik.handleChange}
                  />
                  <ContentNotification type="warning">
                    Make sure that attribute key written in &quot;camelCase&quot; style
                  </ContentNotification>
                </Spacings.Stack>
              )}

              <CheckboxInput
                name="isDefaultKey"
                isChecked={isDefaultKeyUsed}
                onChange={(e) => handleCheckBoxClick(e.target.checked)}
              >
                Use default or Exist Keys?
              </CheckboxInput>
            </Spacings.Stack>
          )}

          <Spacings.Inline justifyContent="flex-end">
            {(formik.isSubmitting || addValues.isLoading) && <LoadingSpinner />}
            <PrimaryButton
              label={"Update product"}
              type="submit"
              isDisabled={
                formik.isSubmitting ||
                addValues.isLoading ||
                !formik.values.typeId ||
                !formik.values.valueIds.length ||
                !formik.values.key
              }
            />
          </Spacings.Inline>
        </Spacings.Stack>
      </form>
    </Card>
  );
};
