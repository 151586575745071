import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProductDestinationMapping } from "@wttb/product-configurator";

import { CreateProductMappingPayload, upsertProductMapping } from "../api/product-mapping";

export function useUpsertProductMapping(productId: string) {
  const queryClient = useQueryClient();

  return useMutation<ProductDestinationMapping, Error, CreateProductMappingPayload>({
    mutationFn: (data) => upsertProductMapping(productId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["PRODUCT_MAPPINGS"] });
    },
  });
}
