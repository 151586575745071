import { lazy } from "react";
import { ApplicationShell } from "@commercetools-frontend/application-shell";
import type { ApplicationWindow } from "@commercetools-frontend/constants";
import { QueryClientProvider } from "@tanstack/react-query";

import { queryClient } from "../../clients/tanstackQueryClient";
import ErrorHandler from "../../common/components/error-handler";
import ManagedModal from "../../common/components/modal";
import { ManagedUIContext } from "../../common/contexts/ui-context";
import loadMessages from "../../load-messages";

declare let window: ApplicationWindow;

// Here we split up the main (app) bundle with the actual application business logic.
// Splitting by route is usually recommended and you can potentially have a splitting
// point for each route. More info at https://reactjs.org/docs/code-splitting.html
const AsyncApplicationRoutes = lazy(() => import("../../routes" /* webpackChunkName: "routes" */));

const EntryPoint = () => (
  <ApplicationShell environment={window.app} applicationMessages={loadMessages}>
    <QueryClientProvider client={queryClient}>
      <ErrorHandler queryClient={queryClient}>
        <ManagedUIContext>
          <AsyncApplicationRoutes />
          <ManagedModal />
        </ManagedUIContext>
      </ErrorHandler>
    </QueryClientProvider>
  </ApplicationShell>
);
EntryPoint.displayName = "EntryPoint";

export default EntryPoint;
