import Modal from "react-modal";
import { CloseIcon, IconButton, Spacings, Text } from "@commercetools-frontend/ui-kit";

import { AttributeTypeEditForm } from "../../features/attribute-type/components/attribute-type-edit-form";
import { SubstrateWeightModal } from "../../features/attribute-value/components/substrate-weight-modal";
import { DestinationMappingEditForm } from "../../features/destination-mapping/components/mapping-edit-form";
import { PrintRateEditForm } from "../../features/print-rate/components/print-rate-edit-form";
import { AddAttributeForm } from "../../features/product/components/add-attribute-form";
import { CopyProductConfigForm } from "../../features/product/components/copy-product-config-form";
import { ProductMappingEditForm } from "../../features/product/components/product-mapping-edit-form";
import { SettingsEditForm } from "../../features/settings/components/settings-edit-form";
import { ModalView, useUI } from "../contexts/ui-context";
import { ConfirmModal } from "./confirm-modal";

const styles = {
  overlay: {
    zIndex: 10000000,
  },
  content: {
    maxWidth: "700px",
    minWidth: "320px",
    overflow: "auto",
    maxHeight: "750px",
    minHeight: "max-content",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const ManagedModal: React.FC = () => {
  const { displayModal, closeModal, modalView } = useUI();
  const header = modalView ? modalView.split("_").join(" ") : "";
  return (
    <Modal
      isOpen={displayModal}
      style={styles}
      appElement={document.getElementById("app")!}
      shouldCloseOnOverlayClick
      onRequestClose={closeModal}
    >
      <Spacings.Stack scale="l">
        <Spacings.Inline justifyContent="space-between" alignItems="center">
          <Text.Subheadline as="h4">{header}</Text.Subheadline>
          <IconButton onClick={closeModal} icon={<CloseIcon />} label={"delete"} />
        </Spacings.Inline>
        <Spacings.Stack>
          {(modalView === ModalView.createAttributeType ||
            modalView === ModalView.updateAttributeType) && <AttributeTypeEditForm />}
          {(modalView === ModalView.createSettings || modalView === ModalView.updateSettings) && (
            <SettingsEditForm />
          )}
          {modalView === ModalView.addAttribute && <AddAttributeForm />}{" "}
          {modalView === ModalView.copyProductConfig && <CopyProductConfigForm />}{" "}
          {modalView === ModalView.confirm && <ConfirmModal />}{" "}
          {modalView === ModalView.createDestinationMapping && <DestinationMappingEditForm />}
          {modalView === ModalView.createProductMapping && <ProductMappingEditForm />}
          {modalView === ModalView.substrateWeight && <SubstrateWeightModal />}
          {(modalView === ModalView.createPrintRate || modalView === ModalView.updatePrintRate) && (
            <PrintRateEditForm />
          )}
        </Spacings.Stack>
      </Spacings.Stack>
    </Modal>
  );
};

export default ManagedModal;
