import {
  Card,
  Constraints,
  LoadingSpinner,
  PrimaryButton,
  SelectField,
  Spacings,
  TextField,
} from "@commercetools-frontend/ui-kit";
import { DestinationMapping, Platform } from "@wttb/product-configurator";
import { useFormik } from "formik";

import { ModalContextState, useUI } from "../../../common/contexts/ui-context";
import { useNotification } from "../../../common/hooks/use-notification";
import { Calculator } from "../../product/api/products";
import { useCreateMapping, useUpdateMapping } from "../hooks/use-create-update-mapping";

type FormData = {
  name: string;
  platform: Platform;
  calculator: Calculator;
};

const calculatorOptions = Object.values(Calculator).map((v) => ({
  value: v,
  label: v,
}));

const platformOptions = Object.values(Platform).map((v) => ({
  value: v,
  label: v,
}));

export const DestinationMappingEditForm: React.FC<{
  mapping?: Partial<DestinationMapping>;
}> = ({ mapping }) => {
  const { closeModal } = useUI() as ModalContextState;
  const updateMapping = useUpdateMapping(mapping?.id || "");
  const createMapping = useCreateMapping();

  const isNew = !mapping;

  useNotification(updateMapping.error || createMapping.error);

  const form = useFormik<FormData>({
    initialValues: {
      name: mapping?.name || "",
      calculator: (mapping?.calculator as Calculator) || Calculator.Flat,
      platform: mapping?.platform || Platform.SiteFlow,
    },
    validate: ({ name, calculator, platform }) => {
      const errors: Partial<Record<keyof FormData, Record<string, boolean>>> = {};
      if (!platform) {
        errors.platform = { required: true };
      }
      if (!name) {
        errors.name = { required: true };
      }
      if (!calculator) {
        errors.calculator = { required: true };
      }
      return errors;
    },
    onSubmit: async ({ name, calculator, platform }) => {
      if (isNew) {
        await createMapping.mutateAsync({ name, calculator, platform });
        return closeModal();
      }
      await updateMapping.mutateAsync({ name, calculator, platform });
      return closeModal();
    },
  });

  return (
    <Card {...(isNew ? { type: "flat", insetScale: "none" } : {})}>
      <form onSubmit={form.handleSubmit}>
        <Spacings.Stack scale="xl">
          <Spacings.Inline scale="m" justifyContent="flex-start" alignItems="flex-start">
            <Constraints.Horizontal max={7}>
              <Spacings.Stack>
                <TextField
                  isRequired
                  id="name"
                  title="Name"
                  name="name"
                  value={form.values.name}
                  errors={TextField.toFieldErrors<FormData>(form.errors).name}
                  onChange={form.handleChange}
                />

                <SelectField
                  isRequired
                  id="calculator"
                  title="Calculator"
                  value={form.values.calculator}
                  options={calculatorOptions}
                  touched={form.touched.calculator}
                  errors={SelectField.toFieldErrors<FormData>(form.errors).calculator}
                  onChange={form.handleChange}
                  menuPortalZIndex={100000000}
                  menuPortalTarget={document.body}
                />
              </Spacings.Stack>
            </Constraints.Horizontal>

            <Constraints.Horizontal {...(!isNew ? { max: 7 } : {})}>
              <SelectField
                isRequired
                id="platform"
                title="Platform"
                value={form.values.platform}
                options={platformOptions}
                touched={form.touched.platform}
                errors={SelectField.toFieldErrors<FormData>(form.errors).platform}
                onChange={form.handleChange}
                menuPortalZIndex={100000000}
                menuPortalTarget={document.body}
              />
            </Constraints.Horizontal>
          </Spacings.Inline>

          <Spacings.Inline justifyContent={isNew ? "flex-end" : "flex-start"}>
            {(form.isSubmitting || createMapping.isLoading || updateMapping.isLoading) && (
              <LoadingSpinner />
            )}
            <PrimaryButton
              label={isNew ? "Create" : "Save"}
              type="submit"
              isDisabled={
                form.isSubmitting ||
                !form.isValid ||
                createMapping.isLoading ||
                updateMapping.isLoading
              }
            />
          </Spacings.Inline>
        </Spacings.Stack>
      </form>
    </Card>
  );
};
