import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // To have more controlled way of data update if user switch Tabs in browser
      refetchOnWindowFocus: false,
    },
  },
});
