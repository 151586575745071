import { useQuery } from "@tanstack/react-query";
import { ProductAttributeValue } from "@wttb/product-configurator";

import { PaginatedResult } from "../../../clients/routes-config";
import { findAllAttributeValues } from "../api/attribute-values";

export function useFindAttributeValuesByType(typeId: string) {
  return useQuery<unknown, Error, PaginatedResult<ProductAttributeValue>>({
    queryFn: () => findAllAttributeValues({ where: { typeId } }),
    queryKey: ["ATTRIBUTE_VALUES", typeId],
    enabled: !!typeId?.length,
  });
}
