import { useCallback, useEffect } from "react";
import { useShowNotification } from "@commercetools-frontend/actions-global";
import {
  DOMAINS,
  NOTIFICATION_KINDS_PAGE,
  NOTIFICATION_KINDS_SIDE,
} from "@commercetools-frontend/constants";

import { getErrorText } from "../utils/get-error-text";

export const useNotification = (err?: unknown) => {
  const showNotification = useShowNotification();
  const showSuccess = () =>
    showNotification({
      kind: NOTIFICATION_KINDS_SIDE.success,
      domain: DOMAINS.SIDE,
      text: "Success 🎉",
    });

  const showError = useCallback(
    (error: unknown) =>
      showNotification({
        kind: NOTIFICATION_KINDS_PAGE.error,
        domain: DOMAINS.PAGE,
        text: getErrorText(error),
      }),
    [showNotification],
  );

  useEffect(() => {
    if (err) {
      showError(err);
    }
  }, [err, showError]);

  return { showSuccess, showError };
};
