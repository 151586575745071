import { ProductAttributeValue } from "@wttb/product-configurator";

import { internalApiFetcher } from "../../../clients/httpFetchers";
import { PaginatedResult, ROUTES } from "../../../clients/routes-config";

export const findAttributeValues = async ({
  where,
  include,
  page,
  pageSize,
}: {
  include: Record<string, unknown>;
  where: Record<string, unknown>;
  page: number;
  pageSize: number;
}): Promise<PaginatedResult<ProductAttributeValue>> => {
  return internalApiFetcher(`${ROUTES.attributeValues}/paginated`, {
    method: "GET",
    params: {
      where,
      include: {
        ...include,
        type: {
          select: {
            id: true,
            name: true,
          },
        },
      },
      page,
      pageSize,
    },
  });
};

export const findAllAttributeValues = async ({
  where = {},
  include = {},
}: {
  where?: Record<string, unknown>;
  include?: Record<string, unknown>;
}): Promise<PaginatedResult<ProductAttributeValue>> => {
  return internalApiFetcher(ROUTES.attributeValues, {
    method: "GET",
    params: { where, include },
  });
};

export const findAttributeValueById = async (id: string): Promise<ProductAttributeValue> => {
  return internalApiFetcher(`${ROUTES.attributeValues}/${id}`, {
    method: "GET",
    params: {
      include: {
        prices: true,
        type: true,
      },
    },
  });
};

export const updateAttributeValue = async (
  id: string,
  data: Partial<ProductAttributeValue>,
): Promise<ProductAttributeValue> => {
  return internalApiFetcher(`${ROUTES.attributeValues}/${id}`, {
    method: "PUT",
    data,
  });
};

export const createAttributeValue = async (
  data: Omit<ProductAttributeValue, "id">,
): Promise<ProductAttributeValue> => {
  return internalApiFetcher(`${ROUTES.attributeValues}`, {
    method: "POST",
    data,
  });
};

export const deleteAttributeValue = async (id: string): Promise<void> => {
  return internalApiFetcher(`${ROUTES.attributeValues}/${id}`, {
    method: "DELETE",
  });
};
