import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Product } from "@wttb/product-configurator";

import { copyProductConfig } from "../api/products";

export function useCopyProductConfig(productId: string) {
  const queryClient = useQueryClient();

  return useMutation<Product, Error, Pick<Product, "id">>({
    mutationFn: (data) => copyProductConfig(productId, data),
    mutationKey: ["COPY_CONFIGURATION"],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["PRODUCT", productId] });
    },
  });
}
