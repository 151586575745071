import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DestinationMapping } from "@wttb/product-configurator";

import { createMapping, updateMapping } from "../api/destination-mappings";

export function useUpdateMapping(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Pick<DestinationMapping, "name" | "calculator" | "platform">) =>
      updateMapping(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["DESTINATION_MAPPING", id] });
    },
  });
}

export function useCreateMapping() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Pick<DestinationMapping, "name" | "calculator" | "platform">) =>
      createMapping(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["DESTINATION_MAPPINGS"] });
    },
  });
}
