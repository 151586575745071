import React from "react";
import {
  AttributeType,
  ProductAttribute,
  ProductDestinationMapping,
} from "@wttb/product-configurator";

import { WeightValue } from "../../features/attribute-value/components/substrate-weight-modal";
import { PrintRate } from "../../features/print-rate/api/print-rate";
import { Settings } from "../../features/settings/types";

export type ModalDataType =
  | AttributeType
  | {
      productId: string;
      attributes?: ProductAttribute[];
    }
  | Settings
  | {
      text: string;
      onClick?: () => void;
    }
  | {
      productId: string;
      calculator: string;
      mapping?: ProductDestinationMapping;
    }
  | {
      onChange: (value: WeightValue) => void;
      typeId: string;
    }
  | PrintRate
  | null;

export interface State {
  displayModal: boolean;
  modalView: string | null;
  modalData: ModalDataType;
  modalCallback: ((...args: unknown[]) => void) | null;
}

type ModalData = {
  data?: ModalDataType;
  callback?: (...args: unknown[]) => void | null;
};

export interface ModalContextState extends State {
  openModal: (view: ModalView, data?: ModalData) => void;
  closeModal: () => void;
}

const initialState: State = {
  displayModal: false,
  modalView: null,
  modalData: null,
  modalCallback: null,
};

type Action =
  | {
      type: "OPEN_MODAL";
      view: ModalView;
      data?: ModalData;
    }
  | { type: "CLOSE_MODAL" };

export enum ModalView {
  createAttributeType = "Create_attribute_type",
  updateAttributeType = "Update_attribute_type",
  addAttribute = "Add_attribute",
  createSettings = "Create_settings",
  updateSettings = "Update_settings",
  copyProductConfig = "Copy_product_configuration",
  confirm = "Confirmation",
  createDestinationMapping = "Create_destination_mapping",
  createProductMapping = "Create_product_mapping",
  substrateWeight = "Substrate_weight",
  createPrintRate = "Create_print_rate",
  updatePrintRate = "Update_print_rate",
}

export const UIContext = React.createContext(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state: State, action: Action): State {
  switch (action.type) {
    case "OPEN_MODAL": {
      return {
        ...state,
        modalView: action.view,
        modalData: action?.data?.data || null,
        modalCallback: action?.data?.callback || null,
        displayModal: true,
      };
    }
    case "CLOSE_MODAL": {
      return { ...initialState };
    }
  }
}

export const UIProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const openModal = (view: ModalView, data?: ModalData) =>
    dispatch({ type: "OPEN_MODAL", view, data });
  const closeModal = () => dispatch({ type: "CLOSE_MODAL" });

  const value = React.useMemo(
    () => ({
      ...state,
      openModal,
      closeModal,
    }),
    [state],
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
  const context = React.useContext(UIContext) as ModalContextState;
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export const ManagedUIContext: React.FC = ({ children }) => <UIProvider>{children}</UIProvider>;
