import { useCallback } from "react";
import {
  AsyncSelectField,
  Card,
  LoadingSpinner,
  PrimaryButton,
  Spacings,
} from "@commercetools-frontend/ui-kit";
import { Product } from "@wttb/product-configurator";
import { useFormik } from "formik";

import { ModalContextState, ModalView, useUI } from "../../../common/contexts/ui-context";
import { findProducts } from "../api/products";
import { useCopyProductConfig } from "../hooks/use-copy-product-config";

type ModalTypeData = {
  productId: string;
};

type WhereType = Record<string, Record<string, string> | Record<string, Record<string, string>>[]>;

type FormType = {
  id?: {
    value: string;
    label: string;
  };
};

export const CopyProductConfigForm: React.FC = () => {
  const { modalData, openModal } = useUI() as ModalContextState & {
    modalData: ModalTypeData;
  };

  const copyConfig = useCopyProductConfig(modalData.productId);

  const handleSubmit = (values: Pick<Product, "id">) => copyConfig.mutateAsync(values);

  const formik = useFormik<FormType>({
    initialValues: {
      id: undefined,
    },
    onSubmit: (values) => {
      if (values.id) {
        openModal(ModalView.confirm, {
          data: {
            text: "Do you agree to process copy operation? Current configuration of Attributes, Incompatible values, Warnings, Tier and Delivery Options will be replaced.",
            onClick: () => handleSubmit({ id: values.id!.value }),
          },
        });
      }
    },
  });

  const loadOptions = useCallback(
    (inputValue: string) => {
      const where: WhereType = {
        NOT: {
          id: modalData.productId,
        },
      };
      if (inputValue) {
        where["OR"] = [
          {
            sku: { contains: inputValue },
          },
          {
            name: { contains: inputValue },
          },
        ];
      }
      return findProducts({
        where,
        page: 1,
        pageSize: 10,
      }).then((data) => {
        return data?.items.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      });
    },
    [modalData.productId],
  );

  return (
    <Card type="flat" insetScale="none">
      <form onSubmit={formik.handleSubmit}>
        <Spacings.Stack scale="xl">
          <AsyncSelectField
            id="id"
            title="Target product"
            placeholder="Search product by name or SKU"
            value={formik.values.id}
            defaultOptions
            cacheOptions
            loadOptions={loadOptions}
            onChange={formik.handleChange}
            menuPortalZIndex={100000000}
            menuPortalTarget={document.body}
          />
          <Spacings.Inline justifyContent="flex-end">
            {copyConfig.isLoading && <LoadingSpinner />}
            <PrimaryButton
              label="Copy"
              type="submit"
              isDisabled={copyConfig.isLoading || !formik.values.id}
            />
          </Spacings.Inline>
        </Spacings.Stack>
      </form>
    </Card>
  );
};
