import { AttributeType } from "@wttb/product-configurator";

import { internalApiFetcher } from "../../../clients/httpFetchers";
import { PaginatedResult, ROUTES } from "../../../clients/routes-config";

export const findAllAttributeTypes = async ({
  where = {},
  include = {},
}: {
  where?: Record<string, unknown>;
  include?: Record<string, unknown>;
}): Promise<PaginatedResult<AttributeType>> => {
  return internalApiFetcher(ROUTES.attributeTypes, {
    method: "GET",
    params: { where, include },
  });
};

export const findAttributeTypes = async ({
  where,
  page,
  pageSize,
}: {
  where: Record<string, unknown>;
  page: number;
  pageSize: number;
}) => {
  return internalApiFetcher(`${ROUTES.attributeTypes}/paginated`, {
    method: "GET",
    params: { where, page, pageSize },
  });
};

export const updateAttributeType = async (
  id: string,
  data: Partial<AttributeType>,
): Promise<AttributeType> => {
  return internalApiFetcher(`${ROUTES.attributeTypes}/${id}`, {
    method: "PUT",
    data,
  });
};

export const createAttributeType = async (
  data: Omit<AttributeType, "id">,
): Promise<AttributeType> => {
  return internalApiFetcher(`${ROUTES.attributeTypes}`, {
    method: "POST",
    data,
  });
};
