import {
  Card,
  ContentNotification,
  Label,
  LoadingSpinner,
  NumberInput,
  PrimaryButton,
  SelectField,
  Spacings,
  Text,
} from "@commercetools-frontend/ui-kit";
import { useFormik } from "formik";

import { ModalContextState, useUI } from "../../../common/contexts/ui-context";
import { PrintProcess, PrintRate } from "../api/print-rate";
import { useUpdatePrintRate } from "../hooks/use-update-print-rate";

const processOptions = Object.values(PrintProcess).map((v) => ({
  value: v,
  label: v,
}));

export const PrintRateEditForm: React.FC = () => {
  const { modalData: rate, closeModal } = useUI() as ModalContextState & {
    modalData: PrintRate;
  };
  const isNew = rate.id === "new";
  const updateRate = useUpdatePrintRate(rate.id);

  const formik = useFormik<Omit<PrintRate, "id" | "createdAt" | "updatedAt">>({
    initialValues: {
      process: rate.process,
      maxSubstrateHeight: rate.maxSubstrateHeight,
      maxSubstrateWidth: rate.maxSubstrateWidth,
      monoOneSideCost: rate.monoOneSideCost,
      monoTwoSidesCost: rate.monoTwoSidesCost,
      colourOneSideCost: rate.colourOneSideCost,
      colourTwoSidesCost: rate.colourTwoSidesCost,
      uplift: rate.uplift,
    },
    validate({ process, ...numberValues }) {
      const errors = {} as Record<string, string>;
      Object.entries(numberValues).reduce((errors, [key, value]) => {
        if (typeof +value !== "number") {
          errors[key] = "Required, Should be a number";
        }
        if (key !== "uplift" && +value < 0) {
          errors[key] = "Must be greater than 0";
        }
        if (!Number.isFinite(+value)) {
          errors[key] = "Required";
        }
        return errors;
      }, errors);
      return errors;
    },
    onSubmit: async (values) => {
      await updateRate.mutateAsync(values);
      closeModal();
    },
    enableReinitialize: true,
  });

  return (
    <Card type="flat" insetScale="none">
      <form onSubmit={formik.handleSubmit}>
        <Spacings.Stack>
          <SelectField
            id="process"
            title="Process"
            value={formik.values.process}
            options={processOptions}
            onChange={formik.handleChange}
            menuPortalZIndex={100000000}
            menuPortalTarget={document.body}
          />

          <Text.Subheadline as="h4">Substrate</Text.Subheadline>

          <Spacings.Inline justifyContent="space-between">
            <Spacings.Stack scale="xs">
              <Label>Max Height</Label>
              <NumberInput
                id="maxSubstrateHeight"
                hasError={!!formik.errors.maxSubstrateHeight}
                value={formik.values.maxSubstrateHeight}
                onChange={formik.handleChange}
              />
            </Spacings.Stack>

            <Spacings.Stack scale="xs">
              <Label>Max Width</Label>
              <NumberInput
                id="maxSubstrateWidth"
                hasError={!!formik.errors.maxSubstrateWidth}
                value={formik.values.maxSubstrateWidth}
                onChange={formik.handleChange}
              />
            </Spacings.Stack>
          </Spacings.Inline>

          <Text.Subheadline as="h4">Mono Colour</Text.Subheadline>

          <Spacings.Inline justifyContent="space-between">
            <Spacings.Stack scale="xs">
              <Label>One side cost</Label>
              <NumberInput
                id="monoOneSideCost"
                hasError={!!formik.errors.monoOneSideCost}
                value={formik.values.monoOneSideCost}
                onChange={formik.handleChange}
              />
            </Spacings.Stack>

            <Spacings.Stack scale="xs">
              <Label>Two sides cost</Label>
              <NumberInput
                id="monoTwoSidesCost"
                hasError={!!formik.errors.monoTwoSidesCost}
                value={formik.values.monoTwoSidesCost}
                onChange={formik.handleChange}
              />
            </Spacings.Stack>
          </Spacings.Inline>

          <Text.Subheadline as="h4">Full Colour</Text.Subheadline>

          <Spacings.Inline justifyContent="space-between">
            <Spacings.Stack scale="xs">
              <Label>One side cost</Label>
              <NumberInput
                id="colourOneSideCost"
                hasError={!!formik.errors.colourOneSideCost}
                value={formik.values.colourOneSideCost}
                onChange={formik.handleChange}
              />
            </Spacings.Stack>

            <Spacings.Stack scale="xs">
              <Label>Two sides cost</Label>
              <NumberInput
                id="colourTwoSidesCost"
                hasError={!!formik.errors.colourTwoSidesCost}
                value={formik.values.colourTwoSidesCost}
                onChange={formik.handleChange}
              />
            </Spacings.Stack>
          </Spacings.Inline>

          <Spacings.Stack scale="xs">
            <Label>Uplift</Label>
            <NumberInput
              id="uplift"
              hasError={!!formik.errors.uplift}
              value={formik.values.uplift}
              onChange={formik.handleChange}
            />
          </Spacings.Stack>

          {!formik.isValid && (
            <ContentNotification type="error">
              {JSON.stringify(formik.errors, null, 2)}
            </ContentNotification>
          )}

          <Spacings.Inline justifyContent="flex-end">
            {(formik.isSubmitting || updateRate.isLoading) && <LoadingSpinner />}
            <PrimaryButton
              label={isNew ? "Create" : "Save"}
              type="submit"
              isDisabled={formik.isSubmitting || updateRate.isLoading || !formik.isValid}
            />
          </Spacings.Inline>
        </Spacings.Stack>
      </form>
    </Card>
  );
};
