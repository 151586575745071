const API_BASE = "/api/configurator";

export type PaginatedResult<T> = {
  total: number;
  page: number;
  limit: number;
  statusCode: number;
  items: T[];
};

export const ROUTES = {
  products: `${API_BASE}/products`,
  attributeValues: `${API_BASE}/attribute-values`,
  attributeTypes: `${API_BASE}/attribute-types`,
  attributes: `${API_BASE}/attributes`,
  prices: `${API_BASE}/prices`,
  incompatibleValues: `${API_BASE}/incompatible-values`,
  productWarnings: `${API_BASE}/product-warnings`,
  settings: `${API_BASE}/settings`,
  price: "api/price",
  destinationMappings: `${API_BASE}/destination-mappings`,
  printRates: `${API_BASE}/print-rates`,
};
