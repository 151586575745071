import { internalApiFetcher } from "../../../clients/httpFetchers";
import { PaginatedResult, ROUTES } from "../../../clients/routes-config";
import { Settings } from "../types";

export const findSettings = async ({
  where,
  page,
  pageSize,
}: {
  where: Record<string, unknown>;
  page: number;
  pageSize: number;
}): Promise<PaginatedResult<Settings>> => {
  return internalApiFetcher(`${ROUTES.settings}/paginated`, {
    method: "GET",
    params: { where, page, pageSize },
  });
};

export const updateSettingsById = async (
  id: string,
  data: Partial<Settings>,
): Promise<Settings> => {
  return internalApiFetcher(`${ROUTES.settings}/${id}`, {
    method: "PUT",
    data,
  });
};

export const createSettings = async (data: Omit<Settings, "id">): Promise<Settings> => {
  return internalApiFetcher(`${ROUTES.settings}`, {
    method: "POST",
    data,
  });
};
