import { useQuery } from "@tanstack/react-query";
import { AttributeType } from "@wttb/product-configurator";

import { PaginatedResult } from "../../../clients/routes-config";
import { findAllAttributeTypes } from "../api/attribute-types";

export function useFindAllAttributeTypes(data?: {
  where?: Record<string, unknown>;
  include?: Record<string, unknown>;
}) {
  return useQuery<unknown, unknown, PaginatedResult<AttributeType>>({
    queryFn: () => findAllAttributeTypes(data || {}),
    queryKey: ["ATTRIBUTE_TYPES", data?.where, data?.include],
  });
}
