import { buildApiUrl, executeHttpClientRequest } from "@commercetools-frontend/application-shell";
import { THeaders } from "@commercetools-frontend/application-shell-connectors/dist/declarations/src/utils/http-client";
import createHttpUserAgent from "@commercetools/http-user-agent";
import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";

// @ts-expect-error: app does not exist on type Window
const { projectKey, applicationName, customAdminApiUrl } = window.app;

const userAgent = createHttpUserAgent({
  name: "axios-client",
  version: "1.0.0",
  libraryName: applicationName,
});

/**
 * Fetcher for the Composable Commerce API.
 *
 * @see https://docs.commercetools.com/custom-applications/concepts/merchant-center-api
 */
export const ctpFetcher = async (path: string, config: AxiosRequestConfig = {}) => {
  const sanitizedPathPart = path.replace(/^\/|\/$/g, "");
  const url = buildApiUrl(`/proxy/ctp/${projectKey}/${sanitizedPathPart}`);

  return executeHttpClientRequest(
    async (options) => {
      const res = await axios(url, {
        ...config,
        headers: options.headers,
        withCredentials: options.credentials === "include",
      });
      return {
        data: res.data,
        statusCode: res.status,
        getHeader: (key) => res.headers[key],
      };
    },
    { userAgent, headers: config.headers as THeaders },
  );
};

/**
 * Function to prepare request config for internal API, the problem here
 * is that CT MerchantCenter don't allow us send PUT and PATCH requests
 * for that added workaround to send request as POST with custom header, but on API side parse
 * custom header and execute needed handler
 */
const prepareInternalAPIConfig = (initialConfig: AxiosRequestConfig) => {
  const config = structuredClone(initialConfig);
  if (["PUT", "PATCH"].includes(config.method || "")) {
    config.headers = {
      ...(config?.headers || {}),
      "x-forward-header-custom-method": config.method,
    };
    config.method = "POST";
  }
  if (config?.data) {
    config.headers = {
      ...(config?.headers || {}),
      "Content-Type": "application/json",
    };
  }
  return config;
};

/**
 * Fetcher for the Internal Custom API
 * Pay attention!!! CT requests to /forward-to do not support PUT, PATCH methods, and body in DELETE method
 * @see https://docs.commercetools.com/custom-applications/concepts/integrate-with-your-own-api
 */
export const internalApiFetcher = async (path: string, config: AxiosRequestConfig = {}) => {
  const sanitizedPathPart = path.replace(/^\/|\/$/g, "");
  const preparedConfig = prepareInternalAPIConfig(config);
  const searchParams = qs.stringify(preparedConfig.params);
  const url = buildApiUrl(`/proxy/forward-to`);

  return executeHttpClientRequest(
    async (options) => {
      const res = await axios(url, {
        ...preparedConfig,
        headers: options.headers,
        withCredentials: options.credentials === "include",
      });
      return {
        data: res.data,
        statusCode: res.status,
        getHeader: (key) => res.headers[key],
      };
    },
    {
      userAgent,
      headers: preparedConfig.headers as THeaders,
      forwardToConfig: {
        uri: `${customAdminApiUrl}/${sanitizedPathPart}?${searchParams}`,
        audiencePolicy: "forward-url-origin",
        includeUserPermissions: true,
      },
    },
  );
};
