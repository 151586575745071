import { useMemo } from "react";
import {
  Card,
  Constraints,
  LoadingSpinner,
  PrimaryButton,
  SelectField,
  Spacings,
  TextField,
} from "@commercetools-frontend/ui-kit";
import { Platform, ProductDestinationMapping } from "@wttb/product-configurator";
import { useFormik } from "formik";

import { ModalContextState, useUI } from "../../../common/contexts/ui-context";
import { useNotification } from "../../../common/hooks/use-notification";
import { useFindAllMappings } from "../../destination-mapping/hooks/use-find-mappings";
import { Calculator } from "../api/products";
import { useUpsertProductMapping } from "../hooks/use-upsert-product-mapping";

type FormData = {
  sku: string;
  platform: Platform;
  destinationMapping: string;
};

type ModalTypeData = {
  productId: string;
  calculator: Calculator;
  mapping?: ProductDestinationMapping;
};

const platformOptions = Object.values(Platform).map((v) => ({
  value: v,
  label: v,
}));

export const ProductMappingEditForm = () => {
  const { closeModal, modalData } = useUI() as ModalContextState & {
    modalData: ModalTypeData;
  };
  const { mapping, calculator, productId } = modalData;
  const upsertMapping = useUpsertProductMapping(productId);

  const isNew = !mapping;

  useNotification(upsertMapping.error);

  const form = useFormik<FormData>({
    initialValues: {
      sku: mapping?.sku || "",
      platform: mapping?.platform || Platform.SiteFlow,
      destinationMapping: mapping?.destinationMappingId || "",
    },
    validate: ({ sku, platform, destinationMapping }) => {
      const errors: Partial<Record<keyof FormData, Record<string, boolean>>> = {};
      if (!platform) {
        errors.platform = { required: true };
      }
      if (!sku) {
        errors.sku = { required: true };
      }
      if (!destinationMapping) {
        errors.destinationMapping = { required: true };
      }
      return errors;
    },
    onSubmit: async ({ sku, platform, destinationMapping }) => {
      await upsertMapping.mutateAsync({
        ...(!isNew ? { id: mapping?.id } : {}),
        sku,
        platform,
        destinationMappingId: destinationMapping,
      });

      return closeModal();
    },
  });

  const { data: { items: destinationMappings = [] } = {}, isFetching } = useFindAllMappings({
    where: {
      platform: form.values.platform,
      calculator: calculator,
    },
  });

  const destinationMappingOptions = useMemo(
    () =>
      destinationMappings.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [destinationMappings],
  );

  return (
    <Card type="flat">
      <form onSubmit={form.handleSubmit}>
        <Spacings.Stack scale="xl">
          <Spacings.Inline scale="m" justifyContent="flex-start" alignItems="flex-start">
            <Constraints.Horizontal max={7}>
              <Spacings.Stack>
                <TextField
                  isRequired
                  id="sku"
                  title="SKU"
                  name="sku"
                  value={form.values.sku}
                  errors={TextField.toFieldErrors<FormData>(form.errors).sku}
                  onChange={form.handleChange}
                />

                <SelectField
                  isRequired
                  id="platform"
                  title="Platform"
                  value={form.values.platform}
                  options={platformOptions}
                  touched={form.touched.platform}
                  errors={SelectField.toFieldErrors<FormData>(form.errors).platform}
                  onChange={form.handleChange}
                  menuPortalZIndex={100000000}
                  menuPortalTarget={document.body}
                />
              </Spacings.Stack>
            </Constraints.Horizontal>

            <Constraints.Horizontal {...(!isNew ? { max: 7 } : {})}>
              <SelectField
                isDisabled={isFetching}
                isRequired
                id="destinationMapping"
                title="Destination mapping"
                value={form.values.destinationMapping}
                options={destinationMappingOptions}
                touched={form.touched.destinationMapping}
                errors={SelectField.toFieldErrors<FormData>(form.errors).destinationMapping}
                onChange={form.handleChange}
                menuPortalZIndex={100000000}
                menuPortalTarget={document.body}
              />
            </Constraints.Horizontal>
          </Spacings.Inline>

          <Spacings.Inline justifyContent={isNew ? "flex-end" : "flex-start"}>
            {(form.isSubmitting || upsertMapping.isLoading) && <LoadingSpinner />}
            <PrimaryButton
              label={isNew ? "Create" : "Save"}
              type="submit"
              isDisabled={
                form.isSubmitting || !form.isValid || upsertMapping.isLoading || isFetching
              }
            />
          </Spacings.Inline>
        </Spacings.Stack>
      </form>
    </Card>
  );
};
