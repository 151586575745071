import {
  Card,
  LoadingSpinner,
  PrimaryButton,
  SecondaryButton,
  Spacings,
  Text,
} from "@commercetools-frontend/ui-kit";

import { ModalContextState, useUI } from "../contexts/ui-context";

type ModalTypeData = {
  text: string;
  onClick: () => void;
  isLoading?: boolean;
};

export const ConfirmModal: React.FC = () => {
  const { modalData, closeModal } = useUI() as ModalContextState & {
    modalData: ModalTypeData;
  };

  return (
    <Card type="flat" insetScale="none">
      <Spacings.Stack scale="xl">
        <Text.Detail>{modalData.text}</Text.Detail>
        <Spacings.Inline justifyContent="flex-end">
          {modalData.isLoading && <LoadingSpinner />}
          <SecondaryButton label="No" onClick={closeModal} isDisabled={modalData.isLoading} />
          <PrimaryButton
            label="Yes"
            onClick={() => {
              modalData.onClick();
              closeModal();
            }}
            isDisabled={modalData.isLoading}
          />
        </Spacings.Inline>
      </Spacings.Stack>
    </Card>
  );
};
