import { ReactElement, useEffect } from "react";
import { reportErrorToSentry } from "@commercetools-frontend/sentry";
import { QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { useNotification } from "../hooks/use-notification";

const isClientSideError = (err: unknown): err is Error => {
  return (
    err instanceof AxiosError &&
    !!err.response?.status &&
    err.response?.status >= 400 &&
    err.response?.status < 500
  );
};

const ErrorHandler: React.FC<{ queryClient: QueryClient }> = ({ queryClient, children }) => {
  const { showSuccess, showError } = useNotification();

  const handleReject = (event: PromiseRejectionEvent) => {
    reportErrorToSentry(event);
  };

  useEffect(() => {
    queryClient.setDefaultOptions({
      mutations: {
        // In case of error data will be undefined and we catch the error on onError block.
        // We can't use onSuccess handler here because
        // we have this handler locally and local handler replace global handler
        onSettled: (data: unknown) => {
          if (data) {
            showSuccess();
          }
        },
        onError: (err: unknown) => {
          if (!isClientSideError(err)) {
            reportErrorToSentry(err as Error);
          }
          showError(err);
        },
      },
    });
  }, [queryClient, showError, showSuccess]);

  useEffect(() => {
    window.addEventListener("unhandledrejection", handleReject);

    return () => window.removeEventListener("unhandledrejection", handleReject);
  }, []);

  return children as ReactElement;
};

export default ErrorHandler;
