import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ModalView, useUI } from "../../../common/contexts/ui-context";
import { createPrintRate, PrintProcess, PrintRate, updatePrintRate } from "../api/print-rate";

export const defaultPrintRate = {
  id: "new",
  process: PrintProcess.Digital,
  maxSubstrateHeight: 0,
  maxSubstrateWidth: 0,
  monoOneSideCost: 0,
  monoTwoSidesCost: 0,
  colourOneSideCost: 0,
  colourTwoSidesCost: 0,
  uplift: 0,
};

const updateOrCreatePrintRate = async (
  id: string,
  data: Omit<PrintRate, "id" | "createdAt" | "updatedAt">,
) => {
  if (id === "new")
    return createPrintRate({
      ...data,
    });
  return updatePrintRate(id, data);
};

export function useUpdatePrintRate(id: string) {
  const queryClient = useQueryClient();
  const { openModal } = useUI();

  return useMutation<PrintRate, Error, Omit<PrintRate, "id" | "createdAt" | "updatedAt">>({
    mutationFn: (data) => updateOrCreatePrintRate(id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["PRINT_RATES"] });
      if (id === "new") {
        openModal(ModalView.updatePrintRate, { data });
      }
      return data;
    },
  });
}
