import { PlainJsonEditor } from "react-plain-json-editor";
import {
  Card,
  Label,
  LoadingSpinner,
  NumberInput,
  PrimaryButton,
  SelectField,
  Spacings,
  TextInput,
} from "@commercetools-frontend/ui-kit";
import { AttributeType, TypeFormat } from "@wttb/product-configurator";
import { useFormik } from "formik";

import { ModalContextState, useUI } from "../../../common/contexts/ui-context";
import { useUpdateAttributeType } from "../hooks/use-update-attribute-type";

const formatOptions = Object.values(TypeFormat).map((v) => ({
  value: v,
  label: v,
}));

export const AttributeTypeEditForm: React.FC = () => {
  const { modalData: type, closeModal } = useUI() as ModalContextState & {
    modalData: AttributeType;
  };
  const isNew = type.id === "new";
  const updateType = useUpdateAttributeType(type.id);

  const formik = useFormik<Omit<AttributeType, "id">>({
    initialValues: {
      name: type.name,
      format: type.format,
      displayIndex: type.displayIndex,
      schema: type.schema || {},
    },
    onSubmit: async (values) => {
      await updateType.mutateAsync(values);
      closeModal();
    },
  });

  return (
    <Card type="flat" insetScale="none">
      <form onSubmit={formik.handleSubmit}>
        <Spacings.Stack>
          <Spacings.Stack scale="xs">
            <Label>Name</Label>
            <TextInput
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Spacings.Stack>

          <Spacings.Stack scale="xs">
            <Label>Display Index</Label>
            <NumberInput
              id="displayIndex"
              value={formik.values.displayIndex}
              onChange={formik.handleChange}
            />
          </Spacings.Stack>

          <SelectField
            id="format"
            title="Value format"
            value={formik.values.format}
            options={formatOptions}
            onChange={formik.handleChange}
            menuPortalZIndex={100000000}
            menuPortalTarget={document.body}
          />

          <Spacings.Stack scale="xs">
            <Label>Schema</Label>
            <PlainJsonEditor
              value={formik.values.schema as Record<string, unknown>}
              showInnerError={true}
              serializer={JSON.parse}
              onChange={(value) => formik.setFieldValue("schema", value)}
              deserializer={(json) => {
                return JSON.stringify(json, null, 2);
              }}
              styles={{
                root: {
                  width: "100%",
                  height: "auto",
                },
                textarea: {
                  padding: 12,
                  fontFamily: "inherit",
                  resize: "vertical",
                },
              }}
            />
          </Spacings.Stack>

          <Spacings.Inline justifyContent="flex-end">
            {(formik.isSubmitting || updateType.isLoading) && <LoadingSpinner />}
            <PrimaryButton
              label={isNew ? "Create" : "Save"}
              type="submit"
              isDisabled={formik.isSubmitting || updateType.isLoading}
            />
          </Spacings.Inline>
        </Spacings.Stack>
      </form>
    </Card>
  );
};
