import { useQuery } from "@tanstack/react-query";
import { DestinationMapping } from "@wttb/product-configurator";

import { PaginatedResult } from "../../../clients/routes-config";
import { findAllDestinationMappings, findDestinationMappings } from "../api/destination-mappings";

export function useFindMappings({ page = 1, pageSize = 10 }) {
  return useQuery<unknown, unknown, PaginatedResult<DestinationMapping>>({
    queryFn: () => findDestinationMappings({ page, pageSize }),
    queryKey: ["DESTINATION_MAPPINGS", page, pageSize],
  });
}

export function useFindAllMappings({ where = {} }) {
  return useQuery<unknown, unknown, PaginatedResult<DestinationMapping>>({
    queryFn: () => findAllDestinationMappings({ where }),
    queryKey: ["DESTINATION_MAPPINGS", where],
  });
}
