import { ProductDestinationMapping } from "@wttb/product-configurator";

import { internalApiFetcher } from "../../../clients/httpFetchers";
import { ROUTES } from "../../../clients/routes-config";

export type CreateProductMappingPayload = Pick<
  ProductDestinationMapping,
  "sku" | "platform" | "destinationMappingId"
> & { id?: string };

export const upsertProductMapping = async (
  productId: string,
  data: CreateProductMappingPayload,
): Promise<ProductDestinationMapping> => {
  return internalApiFetcher(`${ROUTES.products}/${productId}/mappings`, {
    method: "POST",
    data,
  });
};

export const getProductMappings = async (
  productId: string,
): Promise<{ statusCode: string; items: ProductDestinationMapping[] }> => {
  return internalApiFetcher(`${ROUTES.products}/${productId}/mappings`, {
    method: "GET",
  });
};

export const deleteProductMapping = async (
  productId: string,
  id: string,
): Promise<{ success: true }> => {
  return internalApiFetcher(`${ROUTES.products}/${productId}/mappings/${id}`, {
    method: "DELETE",
  });
};
