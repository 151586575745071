import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Product } from "@wttb/product-configurator";

import { addValuesToProduct, AddValuesToProductPayload } from "../api/products";

export function useAddValues(productId: string) {
  const queryClient = useQueryClient();

  return useMutation<Product, Error, AddValuesToProductPayload>({
    mutationFn: (data) => addValuesToProduct(productId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["PRODUCT"] });
    },
  });
}
