import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AttributeType, TypeFormat } from "@wttb/product-configurator";

import { ModalView, useUI } from "../../../common/contexts/ui-context";
import { createAttributeType, updateAttributeType } from "../api/attribute-types";

export const defaultAttributeType = {
  id: "new",
  name: "",
  format: TypeFormat.String,
  schema: {},
  displayIndex: 0,
};

const updateOrCreateAttributeType = async (id: string, data: Omit<AttributeType, "id">) => {
  if (id === "new")
    return createAttributeType({
      ...data,
    });
  return updateAttributeType(id, data);
};

export function useUpdateAttributeType(id: string) {
  const queryClient = useQueryClient();
  const { openModal } = useUI();

  return useMutation<AttributeType, Error, Omit<AttributeType, "id">>({
    mutationFn: (data) => updateOrCreateAttributeType(id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["ATTRIBUTE_TYPES"] });
      if (id === "new") {
        openModal(ModalView.updateAttributeType, { data });
      }
      return data;
    },
  });
}
