import { useMemo } from "react";
import {
  Card,
  PrimaryButton,
  SecondaryButton,
  SelectField,
  Spacings,
} from "@commercetools-frontend/ui-kit";
import { useFormik } from "formik";

import { ModalContextState, useUI } from "../../../common/contexts/ui-context";
import { useFindAttributeValuesByType } from "../hooks/use-find-values-by-type";

export type WeightValue = {
  weight: number;
  weightId: string;
};

type ModalTypeData = {
  onChange: (value: WeightValue) => void;
  typeId: string;
};

export const SubstrateWeightModal: React.FC = () => {
  const { modalData, closeModal } = useUI() as ModalContextState & {
    modalData: ModalTypeData;
  };

  const { data, isLoading, isFetching } = useFindAttributeValuesByType(modalData.typeId);

  const formik = useFormik<{ weightId: string }>({
    initialValues: {
      weightId: "",
    },
    onSubmit: (value) => {
      const weightValue = data?.items?.find((item) => item?.id === value.weightId);
      if (!weightValue) return;
      // We should set Number value to substrate weight
      const numberValue = Number(weightValue?.value);
      if (!isNaN(numberValue)) {
        modalData.onChange({ weight: numberValue, weightId: weightValue.id });
      }
    },
  });

  const selectOptions = useMemo(
    () =>
      (data?.items ?? []).map((v) => ({
        value: v.id,
        label: v.display,
      })),
    [data?.items],
  );

  return (
    <Card type="flat" insetScale="none">
      <Spacings.Stack scale="xl">
        <SelectField
          id="weightId"
          title="Weight"
          value={formik.values.weightId}
          isDisabled={isFetching}
          options={selectOptions}
          onChange={formik.handleChange}
          menuPortalZIndex={100000000}
          menuPortalTarget={document.body}
        />
        <Spacings.Inline justifyContent="flex-end">
          <SecondaryButton label="No" onClick={closeModal} isDisabled={isLoading} />
          <PrimaryButton
            label="Yes"
            onClick={() => {
              formik.handleSubmit();
              closeModal();
            }}
            isDisabled={isLoading}
          />
        </Spacings.Inline>
      </Spacings.Stack>
    </Card>
  );
};
