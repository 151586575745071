import { DestinationMapping, DestinationMappingComponent } from "@wttb/product-configurator";

import { internalApiFetcher } from "../../../clients/httpFetchers";
import { PaginatedResult, ROUTES } from "../../../clients/routes-config";

export const findDestinationMappings = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}): Promise<PaginatedResult<DestinationMapping>> => {
  return internalApiFetcher(`${ROUTES.destinationMappings}/paginated`, {
    method: "GET",
    params: {
      page,
      pageSize,
      include: {
        components: true,
      },
    },
  });
};

export const findAllDestinationMappings = async ({
  where,
}: {
  where: Record<string, unknown>;
}): Promise<DestinationMapping[]> => {
  return internalApiFetcher(`${ROUTES.destinationMappings}`, {
    method: "GET",
    params: {
      where,
      include: {
        components: true,
      },
    },
  });
};

export const getDestinationMappingById = async (id: string): Promise<DestinationMapping> => {
  return internalApiFetcher(`${ROUTES.destinationMappings}/${id}`, {
    method: "GET",
    params: {
      include: {
        components: true,
      },
    },
  });
};

export const createMapping = async (
  data: Pick<DestinationMapping, "name" | "calculator" | "platform">,
): Promise<DestinationMapping> => {
  return internalApiFetcher(`${ROUTES.destinationMappings}/new`, {
    method: "POST",
    data,
  });
};

export const updateMapping = async (
  id: string,
  data: Pick<DestinationMapping, "name" | "calculator" | "platform">,
) => {
  return internalApiFetcher(`${ROUTES.destinationMappings}/${id}`, {
    method: "PUT",
    data,
  });
};

export const deleteMapping = async (mappingId: string): Promise<{ success: true }> => {
  return internalApiFetcher(`${ROUTES.destinationMappings}/${mappingId}`, {
    method: "DELETE",
  });
};

export const upsertMappingComponent = async (
  id: string,
  data: Omit<DestinationMappingComponent, "destinationMappingId" | "id"> & { id?: string },
) => {
  return internalApiFetcher(`${ROUTES.destinationMappings}/${id}/components`, {
    method: "PUT",
    data,
  });
};

export const deleteMappingComponent = async (
  id: string,
  componentId: string,
): Promise<{ success: true }> => {
  return internalApiFetcher(`${ROUTES.destinationMappings}/${id}/components/${componentId}`, {
    method: "DELETE",
  });
};
